export interface ConversionEventOptions {
  valueCents?: number;
  valueCurrency?: string;
  userGuid?: string;
  eventId?: string;
}
export enum ConversionEvent {
  CONFIRMED_REGISTRATION,
  PROFILER_COMPLETE,
  SURVEY_COMPLETE,
}

// we are limited to 8 events. to avoid exceeding this in the future, default to null
const FACEBOOK_EVENT_MAP = {
  [ConversionEvent.CONFIRMED_REGISTRATION]: 'Subscribe',
  [ConversionEvent.SURVEY_COMPLETE]: 'SurveyComplete',
} as Record<ConversionEvent, string>;
export function handleFacebookEvent(event: ConversionEvent, options: ConversionEventOptions) {
  const eventName = FACEBOOK_EVENT_MAP[event];

  if (window.fbq && eventName) {
    // Tracking data:
    const extras = {
      eventID: options.eventId || options.userGuid || undefined,
      event_id: options.eventId || options.userGuid || undefined,
      externalID: `${eventName}-${options.userGuid}` || undefined,
      external_id: `${eventName}-${options.userGuid}` || undefined,
      content_ids: undefined,
      eventRef: undefined,
    };
    window.fbq('track', eventName, extras, extras);
  }
}

export function handleConversionEvent(eventName: ConversionEvent, options: ConversionEventOptions) {
  handleFacebookEvent(eventName, options);
}
